import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from "styled-components"
import Socials from "./socials"

const FooterWrapper = styled.div`
  background: black;
  color: #D3D3D3;
  overflow: auto;

  footer {
    text-align: center;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 16px;

    ul {
      margin: 0 auto;
      list-style-type: none;

      li {
        float: left;
        width: 100%;
        margin-bottom: 8px;
        
        a {
          color: #D3D3D3;
          padding: 8px;
          text-decoration: underline;

          transition: 0.25s color;

          &:hover { 
            color: rgb(0, 173, 239);
          }
        }
      }
    }
  }
`
const Row = styled.div`
  display: table;
  width: 100%;
  overflow: auto;
`
const Column = styled.div`
  display: table-cell;
  width: 33.33%;
  overflow: auto;

  padding-top: 32px;
  padding-bottom: 32px;
  
  &.sub {
    padding-top: 0;
    padding-bottom: 16px;
    font-size: 0.85em;
    text-align: center;
  }

  &.credit a {
    color: #D3D3D3;
    transition: color 0.25s;
    text-decoration: underline;
    &:hover {
      color: rgb(0, 173, 239);
    }
  }

  h3 {
    color: #F5F5F5;
    margin-top: 0;
    border-bottom: 1px solid #D3D3D3;
    display: inline-block;
    padding-bottom: 8px;
    text-transform: uppercase;
  }

  @media screen and (max-width: 700px) {
    display: block;
    width:100%;
  }
`
const SocialWrapper = styled.div`
  width: 50%;
  margin: 0 auto;
`

export default ({data}) => (
  <FooterWrapper>
    <footer>
      <Row>
        <Column>
          <h3>Browse</h3>
          <ul>
            <li><AnchorLink to="/#gallery" title="Gallery" stripHash /></li>
            <li><AnchorLink to="/#bio" title="Biography" stripHash /></li>
            <li><AnchorLink to="/#resume" title="Resume" stripHash /></li>
            {/* <li><a href="https://corynovak.blog">Blog</a></li> */}
            <li><AnchorLink to="/#contact" title="Contact" stripHash /></li>
          </ul>
        </Column>

        <Column>
          <h3>About</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: data.frontmatter.description,
            }}
          />
        </Column>

        <Column>
          <h3>Social</h3>
          <SocialWrapper>
            <Socials data={data} />
          </SocialWrapper>
        </Column>
      </Row>

      <Row>
        <Column className="sub credit">
          Copyright © {(new Date()).getFullYear()}, <a href="https://www.corynovak.com" title="Cory Novak's digital business card"> Cory J. Novak</a> - All Rights Reserved.
        </Column>
        <Column className="sub"></Column>
        <Column className="sub credit">
          Launched by <a href="https://www.mvprocket.com" title="Idea launched into orbit by MvpRocket.com" target="_blank"  rel="noopener noreferrer">MVP Rocket</a>
        </Column>
      </Row>
    </footer>
  </FooterWrapper>
)