import React from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/fontawesome-free-solid'
import Socials from "./socials"

const NavStyled = styled.nav`
  display: table;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  
  text-transform: uppercase;
  
  a {
    color: #F5F5F5 !important;
  }
  #logoWrapper  a {
    transition: 0.25s color;
    
    &:hover {
      color: rgb(0, 173, 239) !important;
    }
  }
  
  h1 {
    display: table-cell;
    vertical-align: middle;
  }

  ul {
    display: table-cell;
    vertical-align: middle;
    width: 60%;
    padding: 0 16px;
    margin: 0;
    
    list-style-type: none;

    div {
      float: right;

      @media screen and (max-width: 972px) {
        width: 100%;
      }
    }

    li {
      float: left;
      margin: 0;
      
      a {
        display: inline-block;
        text-decoration: none;
        border-radius: 2px;

        padding: 8px;
        margin: 0 8px;
        border: 1px solid transparent;
        transition: 0.25s color;
        
        &:hover {
          color: rgb(0, 173, 239) !important;
        }
      }
    }

    #contactNav a {
      border: none;
      cursor: pointer;
      background: rgb(0, 143, 209);
      color: #fff !important;
      border: 1px solid rgb(0, 143, 209);
      text-transform: uppercase;
      border-radius: 2px;
      transition: 0.25s background;
      margin-right: 0;
      &:hover {
        background: rgb(0, 173, 239);
        color: #fff !important;
      }
    }

    @media screen and (max-width: 972px) {
      width: 100%;
      display: block;
      margin: 0 auto;
      border: 16px solid #101010;
      overflow: auto;
      padding: 0;

      li {
        width: 100%;
        text-align: center;
        background: #101010;
        
        a {
          width: 100%;
          margin: 0;
        }
      }

      #contactNav a {
        width: 100%;
        margin: 0;
        margin-top: 16px;
      }
    }
  }
  #hamburger {
    display: none;
  }
  @media screen and (max-width: 972px) {
    #hamburger {
      position: absolute;
      display: inline;
      right: 0;
      top: 18px;
      right: 16px;
      color: #ebebeb;
      font-size: 2em;
      cursor: pointer;
      transition: 0.25s color;
      &:hover {
        color: rgb(0, 173, 239);
      }
    }

    .hidden {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    #hamburger {
      top: 22px;
    }
  }

  .socials {
    display: none;
    background: #101010;
    text-align: center;
    padding-top: 16px;
    @media screen and (max-width: 972px) {
      display: block;
    }
  }
`;

export default ({data, children}) => {
  const [hide, setHide] = React.useState(true)

  const handleClick = () => {
    setHide(!hide);
  }

  return (
    <NavStyled>
      <div id="logoWrapper">{children}</div>
      <FontAwesomeIcon icon="bars" id="hamburger" onClick={handleClick} title="Menu" />
      <ul className={hide ? "hidden" : ""}>
        <div>
          <li><AnchorLink to="/#gallery" title="Gallery" stripHash /></li>
          <li><AnchorLink to="/#bio" title="Biography" stripHash /></li>
          <li><AnchorLink to="/#resume" title="Resume" stripHash /></li>
          {/* <li><a href="https://corynovak.blog">Blog</a></li> */}
          <Socials data={data} />
          <li id="contactNav">
            <AnchorLink to="/#contact" title="Contact" stripHash />
          </li>
        </div>
      </ul>
    </NavStyled>
  );
}