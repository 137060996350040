import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter,
        faLinkedin, faInstagram,
        faYoutube, faImdb } from '@fortawesome/free-brands-svg-icons'

const Container = styled.div`
  a {
    overflow: auto;
    display: inline-block;
    transition: color 0.25s;

    svg {
      margin: 8px;
      color: #D3D3D3;
      cursor: pointer;
      transtion: 0.25s color;
      font-size: 2em;
      transition: color 0.25s;
    }

    &:hover svg {
      color: rgb(0, 173, 239);
    }
  }
`
export default ({data, props}) => (
  <Container className="socials">
    <a href={data.frontmatter.imdb} title="Cory Novak is on IMDB!"
      className={`${data.frontmatter.imdb ? "" : "hide"}`}>
      <FontAwesomeIcon icon={faImdb} />
    </a>
    <a href={data.frontmatter.instagram} title="Cory Novak is on Instagram!"
      className={`${data.frontmatter.instagram ? "" : "hide"}`}>
      <FontAwesomeIcon icon={faInstagram} />
    </a>
    <a href={data.frontmatter.twitter} title="Cory Novak is on Twitter!"
      className={`${data.frontmatter.twitter ? "" : "hide"}`}>
      <FontAwesomeIcon icon={faTwitter} />
    </a>
    <a href={data.frontmatter.facebook} title="Cory Novak is on Facebook!"
      className={`${data.frontmatter.facebook ? "" : "hide"}`}>
      <FontAwesomeIcon icon={faFacebook} />
    </a>
    <a href={data.frontmatter.youtube} title="Cory Novak is on Youtube!"
      className={`${data.frontmatter.youtube ? "" : "hide"}`}>
      <FontAwesomeIcon icon={faYoutube} />
    </a>
    <a href={data.frontmatter.linkedin} title="Cory Novak is on Linkedin!"
      className={`${data.frontmatter.linkedin ? "" : "hide"}`}>
      <FontAwesomeIcon icon={faLinkedin} />
    </a>
  </Container>
)
