import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Nav from "./nav"
import SEO from "./seo"

const HeaderWrapper = styled.div`
  background: #202020;
  color: #D3D3D3;

  header {
    max-width: 1300px;
    margin: 0 auto;
    overflow: hidden;
  }
`
const H1 = styled.h1`
  font-size: 60px;

  margin: 0;
  padding: 16px;
  text-transform: uppercase;

  @media screen and (max-width: 1142px) {
    font-size: 40px;
  }
`

const Logo = ({title}) => (
  <H1
    id="home"
  >
    <Link
      style={{
        boxShadow: `none`,
        textDecoration: `none`,
        color: `inherit`,
      }}
      to={`/`}
    >
      {title}
    </Link>
  </H1>
)

export default ({data}) => (
  <HeaderWrapper>
    <SEO title={data.frontmatter.siteTitle}
      description={data.frontmatter.description} />
    <header>
      <Nav data={data}>
        <Logo title="Cory Novak" />
      </Nav>
    </header>
  </HeaderWrapper>
)